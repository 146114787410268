// components/Navbar/index.js

import React, {useEffect} from "react"
import {
  Nav,
  NavMenu,
  NavBtnLink,
  NavBtnLinkLogIn,
} from "styles/navbarElements"
import {IoSettingsSharp} from "react-icons/io5"
import {pagesDashboards} from "constants/constants"
import {FlexRowContainer} from "styles/styledComponents"
import {useStateContext} from "contexts/userContext"
import {useAuth0} from "@auth0/auth0-react"
import {

  Typography,
  TextField,

} from "@mui/material"
export const Navbar = () => {

  const {isLoading, loginWithRedirect, logout, isAuthenticated, user} = useAuth0()

  const {settings, restaurant_id, setRestaurant_id, setIsLoggingOut, restaurants, isAdmin, setIsAdmin} =
    useStateContext()

  const handleLogout = () => {
    setIsLoggingOut(true)
    localStorage.removeItem("restaurant_id")
    localStorage.removeItem("isAdmin")
    setRestaurant_id(null)
    logout({returnTo: window.location.origin})
  }


  const handleInputChange = (event) => {
    const {name, value} = event.target
    setRestaurant_id(value)
 
  }


  useEffect(() => {

    if (restaurant_id == 58) {
      localStorage.setItem("isAdmin", true)

      setIsAdmin(true)
    }
 
  }, [restaurant_id]);

  return (
    <>

      {/* {!isAuthenticated && !isLoading && (
        <Nav>
          <NavMenu> 
            <FlexRowContainer>
              <Typography sx={{p: 1}}>hello guest, please log in </Typography>
              <NavBtnLinkLogIn to="/" onClick={() => loginWithRedirect()}>
                login
              </NavBtnLinkLogIn>
            </FlexRowContainer>
          </NavMenu>
        </Nav>

      )} */}


      {isAuthenticated && (
        <Nav>
          <NavMenu>
            <FlexRowContainer>
              {pagesDashboards.map((page, index) => (
                <NavBtnLink key={index} to={page.href}>
                  {page.icon} {page.name}
                </NavBtnLink>
              ))}



              {isAdmin &&
                <TextField
                  select
                  name="restaurant"
                  value={restaurant_id}
                  onChange={handleInputChange}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="">None</option>

                  {restaurants?.map((restaurant) => (
                    <option key={restaurant.id} value={restaurant.id}>
                      {restaurant.id} {restaurant.name}

                    </option>
                  ))}



                </TextField>}


            </FlexRowContainer>

            <FlexRowContainer>
              <Typography sx={{p: 1}}>hello {user?.nickname} </Typography>
              {(settings?.logoImage || user?.picture) && (

                <img
                  className="avatar_img"
                  style={{width: "50px"}}
                  src={
                    settings?.logoImage ? settings?.logoImage : user?.picture
                  }
                  alt={user?.nickname}
                />
              )}
            </FlexRowContainer>

            <FlexRowContainer>

              <NavBtnLinkLogIn to="/" onClick={handleLogout}>
                logout
              </NavBtnLinkLogIn>

              <NavBtnLinkLogIn to="/settings">
                <IoSettingsSharp
                  style={{
                    color: "black",
                    fontSize: "20px",
                    marginTop: "5px",
                  }}
                />
              </NavBtnLinkLogIn>
            </FlexRowContainer>
          </NavMenu>

        </Nav>
      )}
    </>
  )
}
