import React from "react"
import {Typography, Paper, List, ListItem, ListItemText} from "@mui/material"
import {FlexColumnContainer, FlexRowContainer} from "styles/styledComponents"
import {useAuth0} from "@auth0/auth0-react"

export function Home() {
  const {isAuthenticated} = useAuth0()

  if (isAuthenticated) {
    return (
      <FlexRowContainer >
        <FlexColumnContainer>
          <Typography variant="h4" align="center" m={2}>
            Welcome to Burger Bot Dashboard
          </Typography>
          <Paper elevation={3} sx={{padding: 3, margin: 2, maxWidth: 800, width: '100%'}}>
            <Typography variant="h5" gutterBottom>
              About Burger Bot
            </Typography>
            <Typography variant="body1" paragraph>
              Burger Bot is an innovative automated burger ordering system designed to streamline the process of taking and managing burger orders. This dashboard provides a centralized interface for managing orders, customizing menu items, and monitoring system performance.
            </Typography>
            <Typography variant="h5" gutterBottom>
              Getting Started
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary="1. Navigate through the dashboard"
                  secondary="Use the navigation menu to access different sections of the dashboard, including Orders, Menu Management, and Analytics."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="2. Monitor incoming orders"
                  secondary="Check the Orders page regularly to view and manage incoming burger orders."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="3. Customize your menu"
                  secondary="Visit the Menu Management page to add, edit, or remove burger options and toppings."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="4. Review performance"
                  secondary="Use the Analytics page to gain insights into order trends, popular items, and overall system performance."
                />
              </ListItem>
            </List>
            <Typography variant="h5" gutterBottom>
              Need Help?
            </Typography>
            <Typography variant="body1">
              If you encounter any issues or have questions about using the Burger Bot Dashboard, please contact our support team at yair7705778@gmail.com .
            </Typography>
          </Paper>
        </FlexColumnContainer>
        {/* <FlexColumnContainer>
          <Typography variant="h4" align="center" m={2}>
            Welcome to Burger Bot Dashboard
          </Typography>
          <Paper elevation={3} sx={{ padding: 3, margin: 2, maxWidth: 800, width: '100%' }}>
           
          </Paper>
        </FlexColumnContainer> */}
      </FlexRowContainer>
    )
  }

  return null
}