import React from 'react';
import {Box, Typography, Grid, Card, CardContent, CardHeader, Container, List, ListItem, ListItemText, Button} from '@mui/material';


import {useAuth0} from "@auth0/auth0-react"
import {Link} from "react-router-dom"


const PlansSection = () => {
  const {loginWithRedirect, loginWithPopup} = useAuth0()

  const plans = [
    {
      title: 'Essential',
      description: 'For Small & Boutique',
      price: '$69 / Monthly',
      features: [
        'Interactive Digital Menu (1)',
        'Full Management Backoffice',
        'QR Code Generator',
        'Traction & Views Data',
      ],
    },
    {
      title: 'PLUS',
      description: 'For Small & Mid Size',
      price: '$99 / Monthly',
      features: [
        'Interactive Digital Menu (1)',
        'Full Management Backoffice',
        'QR Code Generator',
        'Traction & Views Analysis',
        'Designed NFC tags (up to 30)',
        'Profile to Gather All Content (1)',
        'Re-Marketing Tracking Tool',
        'Customer Success Manager',
      ],
    },
    {
      title: 'Unlimited',
      description: 'For Mature Restaurants',
      price: '$149 / Monthly',
      features: [
        'Unlimited Digital Menus',
        'Full Management Backoffice',
        'QR Code Generator',
        'Traction & Views Analysis',
        'Designed NFC tags (up to 50)',
        'Unlimited Profiles',
        'Re-Marketing Tracking Tool',
        'Customer Success Manager',
        'Custom Reports (PDF\'s, Excel, etc.)',
      ],
    },
  ];

  return (
    <Box sx={{py: 8, bgcolor: 'background.paper'}}>
      <Container maxWidth="lg">
        <Typography variant="h3" align="center" color="text.primary" gutterBottom>
          Best Deals
        </Typography>
        <Grid container spacing={4} alignItems="stretch">
          {plans.map(({title, description, price, features, buttonLabel}, index) =>



            <Grid key={index} item xs={12} sm={6} md={4}>
              <Card sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                <CardHeader
                  title={title}
                  titleTypographyProps={{align: 'center'}}
                  sx={{backgroundColor: (theme) => theme.palette.grey[200]}}
                />
                <CardContent sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                  <Box sx={{mb: 2}}>
                    <Typography variant="body1" component="p" align="center" sx={{mb: 1, fontWeight: 'bold'}}>
                      {description}
                    </Typography>
                  </Box>
                  <Box sx={{mb: 2}}>
                    <List>
                      {features.map((feature, index) => (
                        <ListItem key={index}>
                          <ListItemText primary={feature} />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                  <Box sx={{textAlign: 'left', width: '100%'}}>
                    <Typography variant="h6" color="primary" sx={{mb: 2}}>
                      {price}
                    </Typography>






                    <Link to="/"
                      onClick={() =>
                        loginWithRedirect({
                          screen_hint: 'signup',
                        })
                      }
                    >


                      <Button variant="contained" color="primary" fullWidth sx={{mb: 1}}>
                        Start Free Trial
                      </Button>
 
                    </Link>

 
                  </Box>
                </CardContent>
              </Card>
            </Grid>

          )



          }
        </Grid>
      </Container>
    </Box>
  );
};





export default PlansSection;