// translationUtils.js
import axios from 'axios';
import { baseURL } from "constants/api";

export const getTranslationsForText = async (text) => {
  try {
    const response = await axios.post(`${baseURL}/translations/`, { text });
    return response.data;
  } catch (error) {
    console.error('Error executing "getTranslationsForText" request:', error);
    throw error;
  }
};