import React, {useState, useEffect} from "react"
import {

  Typography,
  Button,
  TextField,
  Box,
  Input,
  Checkbox,
  Link
} from "@mui/material"
import axios from "axios"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import {LoadingOverlay} from "components/LoadingOverlay"

import {
  FlexRowContainer,
  FlexColumnContainer,
  ImagePreview,
} from "styles/styledComponents"

import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import {baseURL} from "constants/api"
import {useStateContext} from "contexts/userContext"

export function Settings() {
  const [loading, setLoading] = useState(false)
  const [link, setLink] = useState("")
  const [textToOrder, setTextToOrder] = useState("")
  const [adminNumberTelegram, setAdminNumberTelegram] = useState("")
  const [logoImage, setLogoImage] = useState("")
  const [showCreditCardButton, setShowCreditCardButton] = useState(false)
  const [showApplePayButton, setShowApplePayButton] = useState(false)
  const [showGooglePayButton, setShowGooglePayButton] = useState(false)
  const [showOrderButton, setShowOrderButton] = useState(false)

  const [alertDetail, setAlertDetail] = useState({
    isShow: false,
    severity: "error",
    message: "This is an info alert — check it out!",
  })


  const {restaurant_id, settings} = useStateContext()

  console.log("Settings_restaurantId :>> ", restaurant_id)
  console.log("settings :>> ", settings)

  const handleProductImageUpload = (e) => {
    const file = e.target.files[0]
    TransformFileData(file)
  }

  const TransformFileData = (file) => {
    const reader = new FileReader()

    if (file) {
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        setLogoImage(reader.result)
      }
    } else {
      setLogoImage("")
    }
  }

  const handleLinkChange = (e) => {
    setLink(e.target.value)
  }

  const handleTextToOrder = (e) => {
    setTextToOrder(e.target.value)
  }

  const handleAdminNumberTelegram = (e) => {
    setAdminNumberTelegram(e.target.value)
  }

  const saveChanges = async () => {
    const dataToUpdate = {
      link: link,
      logoImage: logoImage,
      showCreditCardButton: !!showCreditCardButton,
      showApplePayButton: !!showApplePayButton,
      showGooglePayButton: !!showGooglePayButton,
      showOrderButton: !!showOrderButton,
      textToOrder: textToOrder,
      adminNumberTelegram: adminNumberTelegram,
    }

    console.log("dataToUpdate", dataToUpdate)
    try {
      const response = await axios.put(
        `${ baseURL }/settings/${ restaurant_id }`,

        dataToUpdate
      )
      console.log('Запрос "saveChanges" успешно выполнен')
      setLoading(false)

      setAlertDetail({
        isShow: true,
        severity: "success",
        message: "The operation was a success!",
      })

      setTimeout(() => {
        setAlertDetail({
          ...alertDetail,
          isShow: false,
        })
      }, 5000)
    } catch (error) {
      setAlertDetail({
        isShow: true,
        severity: "error",
        message: "The operation was a failure",
      })

      setTimeout(() => {
        setAlertDetail({
          ...alertDetail,
          isShow: false,
        })
      }, 5000)

      setLoading(false)

      console.error('Ошибка при выполнении запроса "saveChanges":', error)
    }
  }

  useEffect(() => {
    if (restaurant_id !== null) {
      console.log("settings :>> ", settings)
      setShowCreditCardButton(!!settings?.showCreditCardButton || false)
      setShowApplePayButton(!!settings?.showApplePayButton || false)
      setShowGooglePayButton(!!settings?.showGooglePayButton || false)
      setShowOrderButton(!!settings?.showOrderButton || false)

      setLink(settings?.link || "")
      setTextToOrder(settings?.textToOrder || "")
      setAdminNumberTelegram(settings?.adminNumberTelegram || "")
      setLogoImage(settings?.logoImage || "")
    }
  }, [settings])

  if (!loading && restaurant_id === null) {
    return (
      <Typography>
        You haven&apos;t created or chosen a restaurant yet
      </Typography>
    )
  }

  return (
    <>
      {/* {loading ? (
				<TableRow>
					<TableCell align="center" colSpan={11}>
						<LinearProgress />
					</TableCell>
				</TableRow>
			) : ( */}

      {loading ? <LoadingOverlay /> : null}

      <FlexColumnContainer sx={{p: 2}}>
        <FlexRowContainer sx={{mt: 2}}>
          <Typography sx={{mr: 2}} variant="h6">
            Link
          </Typography>
          <TextField
            sx={{}}
            value={link}
            onChange={handleLinkChange}
            label="Link"
            variant="outlined"
            fullWidth
          />
        </FlexRowContainer>

        <FlexRowContainer sx={{mt: 2}}>
          <Typography sx={{mr: 2}} variant="h6">
            Text to Order
          </Typography>
          <TextField
            sx={{m: "5px  "}}
            value={textToOrder}
            onChange={handleTextToOrder}
            label="textToOrder"
            variant="outlined"
            fullWidth
          />
        </FlexRowContainer>

        <FlexRowContainer sx={{mt: 2}}>
          <Box>
            <Typography sx={{mr: 2}} variant="h6">
              Chat ID in telegram
            </Typography>
            <Typography sx={{mr: 2}} variant="h7">
          ( You can define a chat_id using the following link :  
              <Link href="https://t.me/username_to_id_bot" target="_blank" rel="noopener noreferrer">
                https://t.me/username_to_id_bot  )
              </Link>
            </Typography>
          </Box>

          <TextField
            sx={{m: "5px  "}}
            value={adminNumberTelegram}
            onChange={handleAdminNumberTelegram}
            label="Chat ID in telegram"
            variant="outlined"
            fullWidth
          />
        </FlexRowContainer>

        <FlexRowContainer>
          <Typography sx={{m: "5px "}} variant="h6">
            Photo in Order
          </Typography>

          <div>
            <label htmlFor="imgUpload">
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
                sx={{
                  "borderRadius": 8,
                  "backgroundColor": "#2196f3",
                  "color": "#fff",
                  "&:hover": {backgroundColor: "#1976d2"},
                }}
              >
                Upload
              </Button>
            </label>
            <Input
              id="imgUpload"
              type="file"
              inputProps={{
                accept: "image/*",
                style: {display: "none"},
              }}
              onChange={handleProductImageUpload}
              required
            />
          </div>

          <ImagePreview>
            {logoImage ? (
              <>
                <img src={logoImage} alt="error!" />
              </>
            ) : (
              <p> Image Preview </p>
            )}
          </ImagePreview>
        </FlexRowContainer>

        <Box sx={{border: "2px solid grey", borderRadius: "5px"}}>
          <FlexRowContainer sx={{justifyContent: "space-between"}}>
            <Typography sx={{m: "5px "}} variant="h6">
              Credit Card Button
            </Typography>
            <Checkbox
              checked={showCreditCardButton}
              onChange={(e) => setShowCreditCardButton(e.target.checked)}
            />
          </FlexRowContainer>
          <FlexRowContainer sx={{justifyContent: "space-between"}}>
            <Typography sx={{m: "5px "}} variant="h6">
              Apple Pay Button
            </Typography>
            <Checkbox
              checked={showApplePayButton}
              onChange={(e) => setShowApplePayButton(e.target.checked)}
            />
          </FlexRowContainer>
          <FlexRowContainer sx={{justifyContent: "space-between"}}>
            <Typography sx={{m: "5px "}} variant="h6">
              Google Pay Button
            </Typography>
            <Checkbox
              checked={showGooglePayButton}
              onChange={(e) => setShowGooglePayButton(e.target.checked)}
            />
          </FlexRowContainer>
          <FlexRowContainer sx={{justifyContent: "space-between"}}>
            <Typography sx={{m: "5px "}} variant="h6">
              Order Button
            </Typography>
            <Checkbox
              checked={showOrderButton}
              onChange={(e) => setShowOrderButton(e.target.checked)}
            />
          </FlexRowContainer>
        </Box>

        <Button
          sx={{m: "5px 0px"}}
          variant="contained"
          color="primary"
          onClick={saveChanges}
        >
          save
        </Button>
      </FlexColumnContainer>

      {alertDetail.isShow && (
        <Box className="notification">
          <Alert severity={alertDetail.severity}>
            <AlertTitle>{alertDetail.severity}</AlertTitle>
            {alertDetail.message}
          </Alert>
        </Box>
      )}
    </>
  )
}
