import "styles/App.scss"
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import {Button, Typography, Box} from "@mui/material"

import {useAuth0} from "@auth0/auth0-react"
import {Navbar} from "components/Navbar"
import {MenuBar} from "components/MenuBar"
import {Header} from "components/Header"
import {pagesDashboards} from "constants/constants"
import {LoadingOverlay} from "components/LoadingOverlay"
import {useEffect, useState} from "react"
import isOnline from "is-online"
import {StartPage} from "pages/StartPage/StartPage"

function App() {
  const {isLoading, error, isAuthenticated} = useAuth0()
  const [onlineStatus, SetOnlineStatus] = useState()

  useEffect(() => {
    CheckingInternet()
  }, [])

  async function CheckingInternet() {
    const online = await isOnline()

    if (online) {
      SetOnlineStatus(true)
      // console.log(" Localhost подключен к интернету. ")
      return
    }

    if (!online) {
      SetOnlineStatus(false)
      console.log(
        "There is no Internet connection. The operation to update the settings has been skipped."
      )
      return false
    }


  }

  return (
    <>
      {!onlineStatus && <p>No internet connection.</p>}
      {onlineStatus && error && <p>Authentication Error</p>}

      {!error && isLoading && <LoadingOverlay />}

      {!isAuthenticated && (
        <>
          <Router>
            <Navbar />
            <StartPage />
          </Router>
        </>
      )}


      {!error && !isLoading && isAuthenticated && (
        <>
          <Router>
            <Navbar />
            <MenuBar />
            <Header />

            <Routes>
              {pagesDashboards.map((page, index) => (
                <Route key={index} path={page.href} element={page.element} />
              ))}

              {/* <Route path="/qr-code-generator" element={<QRCodeGenerator />} /> */}
            </Routes>
          </Router>
        </>
      )}
    </>
  )
}

export default App
