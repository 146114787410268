import {useAuth0} from "@auth0/auth0-react"
import {createContext, useContext, useState, useEffect} from "react"
import axios from "axios"
import {baseURL} from "constants/api"

const StateContext = createContext({
  restaurant_id: null,
  isLoggingOut: false,
  setRestaurant_id: () => { },
  setIsLoggingOut: () => { },
})

export const ContextProvider = ({children}) => {
  const {user, isAuthenticated} = useAuth0()
  const [restaurant_id, setRestaurant_id] = useState(
    localStorage.getItem("restaurant_id") || null
  )
  // const [isAuthorized, setIsAuthorized] = useState(isAuthenticated)
  const [isLoggingOut, setIsLoggingOut] = useState(false)
  const [settings, setSettings] = useState([])
  const [restaurants, setRestaurants] = useState([])
  const [loading, setLoading] = useState(true)
  const [apiCalled, setApiCalled] = useState(false)
  const [isAdmin, setIsAdmin] = useState( localStorage.getItem("isAdmin") || false)
  
 
  

  const getSettings = async () => {
    try {
      setLoading(true)
      if (!restaurant_id) {
        console.warn("restaurant_id is null or undefined")
        setLoading(false)
        return
      }

      console.log(`Fetching settings from ${ baseURL }/settings/${ restaurant_id }`)
      const response = await axios.get(`${ baseURL }/settings/${ restaurant_id }`)
      setSettings(response.data[0])
      console.log("Settings fetched successfully :", response.data[0])
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error while executing "getSettings" request:', error)
    }
  }

  const getRestaurants = async () => {
    try {

      console.log(`get Restaurants from ${ baseURL }/restaurants/`)
      const response = await axios.get(`${ baseURL }/restaurants/`)


      setRestaurants(response.data)

      console.log("Restaurants fetched successfully")
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error while executing "getRestaurants" request:', error)
    }
  }

  const getUserRestaurant = async () => {
    if (isAuthenticated && restaurant_id === null && !apiCalled && !isLoggingOut) {

      try {
        const url = `${ baseURL }/restaurants/user/${ user.sub }`
        const response = await fetch(url)

        if (!response.ok) {
          if (response.status === 404) {
            console.log(
              "User does not have a restaurant yet. Creating a new one..."
            )
            await createRestaurant()
          } else {
            throw new Error(`HTTP error! Status: ${ response.status }`)
          }
        } else {
          const data = await response.json()
          if (data.length > 0) {
            _setRestaurant_id(data[0].id)
          } else {
            await createRestaurant()
          }
        }
      } catch (error) {
        console.log(
          'Error while executing "getUserRestaurant" request -- maybe you need start server!'
        )

        console.error(
          'Error while executing "getUserRestaurant" request:',
          error
        )
      }
      setApiCalled(true)
    }
  }
  const createRestaurant = async () => {

    try {
      const response = await axios.post(`${ baseURL }/restaurants`, {
        userSub: user.sub,
      })
      console.log("create called")
      _setRestaurant_id(response.data.id)
    } catch (error) {
      console.error("Ошибка при создании ресторана:", error)
    }
  }

  useEffect(() => {
    console.log("ContextProvider_useEffect_restaurantId :>> ", restaurant_id)
    if (restaurant_id !== null) {
      getSettings()
    }

  }, [restaurant_id])



  useEffect(() => {
    if (isAuthenticated && !isLoggingOut) {
      getUserRestaurant()
      getRestaurants()

    }
  }, [isAuthenticated, isLoggingOut, user?.sub])

  const _setRestaurant_id = (id) => {
    console.log("_setRestaurant_id id :>> ", id)
    if (id !== null) {
      setRestaurant_id(id)
      localStorage.setItem("restaurant_id", id)
    } else {
      setRestaurant_id(null)
      localStorage.removeItem("restaurant_id")
    }
  }

  return (
    <StateContext.Provider
      value={{
        settings,
        loading,
        setLoading,
        restaurant_id,
        isLoggingOut,
        setRestaurant_id: _setRestaurant_id,
        setIsLoggingOut,
        restaurants, isAdmin, setIsAdmin
      }}
    >
      {children}
    </StateContext.Provider>
  )
}

export const useStateContext = () => useContext(StateContext)
