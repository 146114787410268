import React, {useState, useEffect} from "react"
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  TextField,
  LinearProgress,
  Box,
  Input,
  IconButton,
} from "@mui/material"
import {TranslationModal} from "components/TranslationModal"
import {getTranslationsForText} from "utils/translationUtils"
import axios from "axios"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"

import {
  FlexRowContainer,
  FlexColumnContainer,
  StyledButton,
  ImagePreview,
} from "styles/styledComponents"
import {baseURL} from "constants/api"
import {LoadingOverlay} from "components/LoadingOverlay"
import {useStateContext} from "contexts/userContext"

export function Toppings() {
  const {restaurant_id} = useStateContext()
  const [toppingsList, setToppingsList] = useState([])
  const [originalToppings, setOriginalToppings] = useState([])
  const [newTopping, setNewTopping] = useState({
    title: "",
    price: "",
    image: "",
    translations: {
      title: {en: "", he: "", ru: "", fr: ""},
    }
  })
  const [logoImage, setLogoImage] = useState("")
  const [successAlert, setSuccessAlert] = useState(false)
  const [loading, setLoading] = useState(true)
  const [openTranslationModal, setOpenTranslationModal] = useState(false)
  const [currentTranslationField, setCurrentTranslationField] = useState(null)
  const [currentToppingIndex, setCurrentToppingIndex] = useState(null)
  const [translations, setTranslations] = useState({
    title: {en: "", he: "", ru: "", fr: ""},
  })
  const [isTranslating, setIsTranslating] = useState(false)

  const getToppings = async () => {
    setLoading(true)

    try {
      const response = await axios.get(`${ baseURL }/toppings/${ restaurant_id }`)
      console.log("getToppings_response.data", response.data)
      setToppingsList(response.data)
      // setOriginalToppings(response.data)
      setOriginalToppings(JSON.parse(JSON.stringify(response.data)))

      setLoading(false)
    } catch (error) {
      setLoading(false)

      console.error('Ошибка при выполнении запроса "getToppings":', error)
    }
  }

  const addTopping = async () => {
    const data = {
      title: newTopping.title,
      price: newTopping.price,
      image: logoImage,
      restaurant_id: restaurant_id,
      translations: {
        title: {en: "", he: "", ru: "", fr: ""},
      }
    }

    console.log("newDish :>> ", data)
    setLoading(true)

    try {
      const response = await axios.post(
        `${ baseURL }/toppings/`,

        data
      )
      console.log('Запрос "addMenuItem" успешно выполнен')
      setLoading(false)

      setNewTopping({
        title: "",
        price: "",
        image: null,
      })
      setLogoImage(null)

      getToppings()
      setSuccessAlert(true)

      setTimeout(() => {
        setSuccessAlert(false)
      }, 3000)
    } catch (error) {
      setLoading(false)

      console.error('Ошибка при выполнении запроса "addMenuItem":', error)
      return
    }
  }

  const updateTopping = async (index) => {
    const updatedTopping = toppingsList[index]
    setLoading(true)

    try {
      await axios.put(`${ baseURL }/toppings`, updatedTopping)
      setLoading(false)

      setSuccessAlert(true)

      setTimeout(() => {
        setSuccessAlert(false)
      }, 3000)
      console.log("Топпинг успешно обновлен")
      getToppings()
    } catch (error) {
      setLoading(false)

      console.error("Ошибка при обновлении топпинга:", error)
    }
  }

  const deleteTopping = async (index) => {
    setLoading(true)

    try {
      const toppingIdToDelete = toppingsList[index].id
      await axios.delete(`${ baseURL }/toppings/${ toppingIdToDelete }`)
      setLoading(false)

      setSuccessAlert(true)
      setTimeout(() => {
        setSuccessAlert(false)
      }, 3000)
      console.log("Топпинг успешно удален")
      getToppings()
    } catch (error) {
      setLoading(false)

      console.error("Ошибка при удалении топпинга:", error)
    }
  }

  useEffect(() => {
    if (restaurant_id !== null) {
      getToppings()
    }
  }, [restaurant_id])

  const handleProductImageUpload = (e) => {
    // const file = e.target.files[0];
    const file = e.currentTarget.files[0]

    TransformFileData(file)
  }

  const TransformFileData = (file) => {
    const reader = new FileReader()

    if (file) {
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        setLogoImage(reader.result)
      }
    } else {
      setLogoImage("")
    }
  }

  const handleInputChange = (event) => {
    const {name, value} = event.target

    // Проверяем, что вводимое значение содержит только цифры
    if (name === "price" && !/^\d+$/.test(value)) {
      return // Прерываем выполнение функции, если ввод не является числом
    }

    setNewTopping({
      ...newTopping,
      [name]: value,
    })
  }
  const handleOpenTranslationModal = (index, field) => {
    setCurrentToppingIndex(index)
    setCurrentTranslationField(field)
    setTranslations({
      title: toppingsList[index].translations || {
        en: "",
        he: "",
        ru: "",
        fr: "",
      },
    })
    setOpenTranslationModal(true)
  }

  const handleCloseTranslationModal = () => {
    setOpenTranslationModal(false)
  }

  const handleTranslationChange = (e, language) => {


    const {value} = e.target
    setTranslations((prev) => ({
      ...prev,
      [currentTranslationField]: {
        ...prev[currentTranslationField],
        [language]: value,
      },
    }))

  }

  const handleSaveTranslations = () => {
    const updatedToppingsList = [...toppingsList]
    updatedToppingsList[currentToppingIndex].translations = translations.title
    setToppingsList(updatedToppingsList)
    setOpenTranslationModal(false)
  }

  const handleAutoTranslate = async () => {
    setIsTranslating(true)

    try {
      const request = toppingsList[currentToppingIndex].title
      const translationObj = await getTranslationsForText(request)
      setTranslations({
        title: translationObj,
      })
      setSuccessAlert(true)
      setTimeout(() => {
        setSuccessAlert(false)
      }, 3000)
    } catch (error) {
      console.error("Error in auto-translation:", error)
      // Handle error (e.g., show error message to user)
    } finally {
      setIsTranslating(false)
    }
  }

  const updateItems = async () => {
    console.log("toppingsList", toppingsList)
    console.log("originalToppings", originalToppings)

    // Function to compare objects
    const isEqual = (obj1, obj2) => {
      return JSON.stringify(obj1) === JSON.stringify(obj2)
    }

    setLoading(true)
    try {
      const updatedToppings = []
      for (const topping of toppingsList) {
        const originalTopping = originalToppings.find(
          (item) => item.id === topping.id
        )
        if (!isEqual(originalTopping, topping)) {
          console.log("!isEqual :>> ", originalTopping, topping)
          try {
            const response = await axios.put(`${ baseURL }/toppings`, topping)
            console.log(`Dish with ID ${ topping.id } successfully updated`)
            console.log(`Dish with title ${ topping.title } successfully updated`)
            setSuccessAlert(
              `Dish with title ${ topping.title } successfully updated`
            )

            // updatedDishes.push(response.data) // Assuming the server returns the updated dish
          } catch (error) {
            console.error(`Error updating dish with ID ${ topping.id }:`, error)
            updatedToppings.push(topping) // Save the original dish in case of an error
          }
        } else {
          updatedToppings.push(topping)
        }
      }

      getToppings()

      setLoading(false)
      setTimeout(() => {
        setSuccessAlert(false)
      }, 5000)
    } catch (error) {
      setLoading(false)
      console.error('Error executing the "updateItems" request:', error)
    }
  }

  return (
    <>
      {successAlert && (
        <Box className="notification">
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            The operation was a — <strong>success!</strong>
          </Alert>
        </Box>
      )}

      {!loading && toppingsList.length === 0 && (
        <Box sx={{width: "100%"}}>
          <Typography>You haven&apos;t added toppings yet</Typography>
        </Box>
      )}

      {/* {console.log("loading", loading)} */}
      {/* {loading && (
        <Box sx={{ width: "100%" }}>
          <LoadingOverlay />
        </Box>
      )} */}

      <Paper>
        <Box sx={{textAlign: "right"}}>
          <Button
            sx={{m: "5px "}}
            variant="contained"
            color="primary"
            onClick={() => updateItems()}
          >
            Save All
          </Button>
        </Box>

        <Table>
          <TableHead>
            <TableRow sx={{backgroundColor: "lightBlue"}}>
              <TableCell>Title</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {toppingsList.length > 0 &&
              toppingsList.map((topping, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <FlexColumnContainer>
                      <TextField
                        value={topping.title}
                        onChange={(e) => {
                          const updatedList = [...toppingsList]
                          updatedList[index].title = e.target.value
                          setToppingsList(updatedList)
                        }}
                      />
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          handleOpenTranslationModal(index, "title")
                        }
                      >
                        Translations
                      </Button>
                    </FlexColumnContainer>
                  </TableCell>
                  <TableCell sx={{width: 100}}>
                    <TextField
                      value={topping.price}
                      onChange={(e) => {
                        const {value} = e.target
                        // Проверяем, что вводимое значение содержит только цифры
                        if (!/^\d*$/.test(value)) {
                          return // Прерываем выполнение функции, если ввод не является числом
                        }
                        const updatedList = [...toppingsList]
                        updatedList[index].price = value
                        setToppingsList(updatedList)
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Box>
                      <ImagePreview>
                        {topping.image ? (
                          <>
                            <img src={topping.image} alt="no_image!" />
                          </>
                        ) : (
                          <Typography> Image Preview </Typography>
                        )}
                      </ImagePreview>

                      <label htmlFor={`imgUpload-${ index }`}>
                        <Button
                          variant="contained"
                          component="span"
                          startIcon={<CloudUploadIcon />}
                          sx={{
                            "borderRadius": 8,
                            "backgroundColor": "#2196f3",
                            "color": "#fff",
                            "&:hover": {backgroundColor: "#1976d2"},
                          }}
                        >
                          Upload
                        </Button>
                      </label>
                      <Input
                        id={`imgUpload-${ index }`}
                        type="file"
                        inputProps={{
                          accept: "image/*",
                          style: {display: "none"},
                        }}
                        onChange={(e) => {
                          const updatedList = [...toppingsList]
                          const file = e.target.files[0]
                          const reader = new FileReader()

                          if (file) {
                            reader.readAsDataURL(file)
                            reader.onloadend = () => {
                              // setLogoImage(reader.result);
                              console.log("index", index)
                              updatedList[index].image = reader.result
                              console.log("updatedList", updatedList)

                              setToppingsList(updatedList)
                            }
                          } else {
                            setToppingsList([...toppingsList])
                          }
                        }}
                        required
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={() => updateTopping(index)}
                    >
                      Update
                    </StyledButton>
                    <StyledButton
                      variant="contained"
                      color="secondary"
                      onClick={() => deleteTopping(index)}
                    >
                      Delete
                    </StyledButton>
                  </TableCell>
                </TableRow>
              ))}

            {/* // Add new Topping================================================ */}

            <TableRow sx={{backgroundColor: "lightBlue"}}>
              <TableCell>Title</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <TextField
                  label="Title"
                  name="title"
                  value={newTopping.title}
                  onChange={handleInputChange}
                  sx={{m: "10px"}}
                />
              </TableCell>

              <TableCell sx={{width: 150}}>
                <TextField
                  label="Price"
                  name="price"
                  value={newTopping.price}
                  onChange={handleInputChange}
                  sx={{m: "10px"}}
                />
              </TableCell>

              <TableCell>
                <ImagePreview>
                  {logoImage ? (
                    <>
                      <img src={logoImage} alt="error!" />
                    </>
                  ) : (
                    <Typography> Image Preview </Typography>
                  )}
                </ImagePreview>

                <label htmlFor="imgUpload">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    sx={{
                      "borderRadius": 8,
                      "backgroundColor": "#2196f3",
                      "color": "#fff",
                      "&:hover": {backgroundColor: "#1976d2"},
                    }}
                  >
                    Upload
                  </Button>
                </label>
                <Input
                  id="imgUpload"
                  type="file"
                  inputProps={{
                    accept: "image/*",
                    style: {display: "none"},
                  }}
                  onChange={handleProductImageUpload}
                  required
                />
              </TableCell>

              <TableCell>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={addTopping}
                >
                  Add Topping
                </StyledButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>

      <TranslationModal
        open={openTranslationModal}
        onClose={handleCloseTranslationModal}
        translations={translations.title}
        onTranslationChange={handleTranslationChange}
        onSave={handleSaveTranslations}
        onAutoTranslate={handleAutoTranslate}
        isLoading={isTranslating}
      />
    </>
  )
}
