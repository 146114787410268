import React, {useState} from "react"
import {
  Typography,
  Box,
  IconButton,
  Dialog,
  DialogContent,
} from "@mui/material"
import {useLocation} from "react-router-dom"
import theme from "styles/theme"
import {useAuth0} from "@auth0/auth0-react"
import InfoIcon from "@mui/icons-material/Info"

export const Header = () => {
  const {isAuthenticated} = useAuth0()
  const location = useLocation()
  const [open, setOpen] = useState(false)

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const namePage = location.pathname.slice(1)

  console.log("namePage :>> ", namePage)

  return (
    <>
      {isAuthenticated && (
        <Box
          sx={{
            width: "100%",
            backgroundColor: theme.blue2,
            textAlign: "center",
            position: "relative",
          }}
        >
          <Typography variant="h4" p="10px" >
            {!namePage ? "Home Page" : capitalizeFirstLetter(namePage)}
          </Typography>

          <IconButton
            sx={{position: "absolute", top: "10px", right: "10px"}}
            color="inherit"
            onClick={handleClickOpen}
          >
            <InfoIcon />
          </IconButton>
          <Dialog open={open} onClose={handleClose}>
            <DialogContent>
              <img
                src={`../images/instructions/${ namePage }/${ namePage }.png`}
                alt="instruction"
                style={{width: "100%"}}
                className="broken-img"
              />
              {/* 
              <img
                src={`../images/instructions/${namePage}/${namePage}.png`}
                alt="instruction"
                style={{ width: "100%" }}
                onError={(e) => {
                  e.target.onerror = null
                  e.target.src = "../images/instructions/placeholder.png"
                }}
              /> */}
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </>
  )
}
