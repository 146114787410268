import React, {useState, useEffect} from "react"
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  TextField,
  LinearProgress,
  Box,
  Input,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material"
import TranslateIcon from "@mui/icons-material/Translate"

import Autocomplete from "@mui/material/Autocomplete"
import Stack from "@mui/material/Stack"
import axios from "axios"
import {useAuth0} from "@auth0/auth0-react"
import {
  ImagePreview,
  StyledTableCell,
  FlexColumnContainer,
  FlexRowContainer,
  DialogTextField,
  FullSizeTextarea,
} from "styles/styledComponents"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import {baseURL} from "constants/api"
import {LoadingOverlay} from "components/LoadingOverlay"
import {useStateContext} from "contexts/userContext"
import {TranslationModal} from "components/TranslationModal"
import {getTranslationsForText} from "utils/translationUtils"

export const Dishes = React.memo(function Dishes() {
  const {restaurant_id} = useStateContext()
  // const user = {
  // 	nickname: "cafecafe",
  // 	picture: "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png",
  // };
  const [productImg, setProductImg] = useState("")
  const [isTranslating, setIsTranslating] = useState(false)

  const [selectedToppings, setSelectedToppings] = useState([])
  const [newSelectedToppings, setNewSelectedToppings] = useState([])
  const [newSelectedGroup, setNewSelectedGroup] = useState(null)

  const [selectedExtras, setSelectedExtras] = useState([])
  const [newSelectedExtras, setNewSelectedExtras] = useState([])

  const [successAlert, setSuccessAlert] = useState(false)

  const [dishes, setDishes] = useState([])
  const [originalDishes, setOriginalDishes] = useState([])
  const [groups, setGroups] = useState([])
  const [toppings, setToppings] = useState([])
  const [extras, setExtras] = useState([])
  const [newDish, setNewDish] = useState({
    title: "",
    description: "",
    price: null,
    toppings: [],
    extras: [],
    image: "",
    group_obj: {},
  })
  const [loading, setLoading] = useState(false)

  const [openTranslationModal, setOpenTranslationModal] = useState(false)
  const [currentTranslationField, setCurrentTranslationField] = useState(null)
  const [currentDishIndex, setCurrentDishIndex] = useState(null)
  const [translations, setTranslations] = useState({
    title: {en: "", he: "", ru: "", fr: ""},
    description: {en: "", he: "", ru: "", fr: ""},
  })
  const [errorAlert, setErrorAlert] = useState(false)

  const handleOpenTranslationModal = (index, field) => {
    setCurrentDishIndex(index)
    setCurrentTranslationField(field)
    setTranslations({
      title: dishes[index].translations || {en: "", he: "", ru: "", fr: ""},
      description: dishes[index].translations_descriptions || {en: "", he: "", ru: "", fr: ""},
    })
    setOpenTranslationModal(true)
  }

  const handleAutoTranslate = async () => {
    setIsTranslating(true)
    try {
      const request = currentTranslationField === "title"
        ? dishes[currentDishIndex].title
        : dishes[currentDishIndex].description
      const translationObj = await getTranslationsForText(request)
      setTranslations({
        ...translations,
        [currentTranslationField]: translationObj,
      })
      setSuccessAlert("Auto-translation completed successfully")
      setTimeout(() => {
        setSuccessAlert(false)
      }, 3000)
    } catch (error) {
      console.error("Error in auto-translation:", error)
    } finally {
      setIsTranslating(false)
    }
  }


  const handleCloseTranslationModal = () => {
    setOpenTranslationModal(false)
  }

  const handleTranslationChange = (e, language) => {
    const {value} = e.target
    setTranslations((prev) => ({
      ...prev,
      [currentTranslationField]: {
        ...prev[currentTranslationField],
        [language]: value,
      },
    }))
  }

  const handleSaveTranslations = () => {
    const updatedDishes = [...dishes]
    if (currentTranslationField === "description") {
      updatedDishes[currentDishIndex].translations_descriptions = translations.description
    } else {
      updatedDishes[currentDishIndex].translations = translations.title
    }
    setDishes(updatedDishes)
    setOpenTranslationModal(false)
  }

  const handleProductImageUpload = (e) => {
    const file = e.target.files[0]
    TransformFileData(file)
  }

  const TransformFileData = (file) => {
    const reader = new FileReader()

    if (file) {
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        setProductImg(reader.result)
      }
    } else {
      setProductImg("")
    }
  }

  const handleNewDishChange = (e) => {
    const {name, value} = e.target

    // setNewDish({ ...newDish, [name]: value })
    setNewDish({
      ...newDish,
      [name]: name === "price" ? parseFloat(value) : value,
    })

    setErrorAlert(false) // Remove the change error message
  }

  const handleEditChange = (e, index, field) => {
    const newValue = e.target.value
    const updatedDishes = [...dishes]
    updatedDishes[index][field] =
      field === "group_obj" ? newValue?.id : newValue
    updatedDishes[index][field] =
      field === "price" ? parseFloat(newValue) : newValue

    console.log("handleEditChange_updatedDishes :>> ", updatedDishes)
    setDishes(updatedDishes)
    setErrorAlert(false) // Remove the change error message
  }

  const getMenu = async () => {
    setLoading(true)

    try {
      const response = await axios.get(`${ baseURL }/dishes/${ restaurant_id }`)
      setLoading(false)
      console.log("getMenu_response.data", response.data)

      setDishes(response.data)
      setOriginalDishes(JSON.parse(JSON.stringify(response.data)))
      console.log('The "getMenu" request was successful')

      //   const receivedDishes = response.data;
      //   setDishes(receivedDishes);
      //   setOriginalDishes([...receivedDishes]); // Create a copy of the received dishes and save in originalDishes
      //   console.log('The "getMenu" request was successful');
    } catch (error) {
      setLoading(false)
      console.error('Error executing the "getMenu" request:', error)
      return
    }
  }

  const getToppings = async () => {
    try {
      const response = await axios.get(`${ baseURL }/toppings/${ restaurant_id }`)

      console.log("getToppings_response.data", response.data)
      setToppings(response.data)

      console.log('The "getToppings" request was successful')
    } catch (error) {
      console.error('Error executing the "getToppings" request:', error)
      return
    }
  }
  const getExtras = async () => {
    try {
      const response = await axios.get(`${ baseURL }/extras/${ restaurant_id }`)

      console.log("getExtras.data", response.data)
      setExtras(response.data)

      console.log('The "getExtras" request was successful')
    } catch (error) {
      console.error('Error executing the "getExtras" request:', error)
      return
    }
  }

  const getGroups = async () => {
    try {
      const response = await axios.get(`${ baseURL }/groups/${ restaurant_id }`)

      console.log("groups_getGroups.data", response.data)
      setGroups(response.data)

      console.log('The "getGroups" request was successful')
    } catch (error) {
      console.error('Error executing the "getGroups" request:', error)
      return
    }
  }

  const validateForm = (dish) => {
    console.log("dish :>> ", dish)
    // Check that all mandatory fields are filled in
    if (!dish.title || !dish.price) {
      setErrorAlert("Please fill in all fields. (title, price) ")
      return false
    }

    // Allow only digits and one decimal point
    if (typeof dish.price !== "number") {
      setErrorAlert("The 'price' field must contain only numbers.")
      return false
    }

    // If all checks are passed
    setErrorAlert(null) // Reset the error message
    return true
  }

  const addNewDish = async (index) => {
    const dishToAdd = {
      ...newDish,
      image: productImg,
      restaurant_id: restaurant_id,
      toppings: newSelectedToppings,
      extras: newSelectedExtras,
      group_obj: newSelectedGroup,
    }

    if (!validateForm(dishToAdd)) return // Проверяем форму перед отправкой

    setLoading(true)

    try {
      const response = await axios.post(`${ baseURL }/dishes/`, dishToAdd)
      setLoading(false)

      console.log('The "addNewDish" request was successful')
      setSuccessAlert(`The creating ${ dishToAdd.title } was successful`)
      setProductImg(null)
      setTimeout(() => {
        setSuccessAlert(false)
      }, 5000)
    } catch (error) {
      setLoading(false)
      console.error('Error executing the "addNewDish" request:', error)
      return
    }
    setDishes([...dishes, dishToAdd])

    setNewDish({
      title: "",
      description: "",
      price: "",
      toppings: [],
      image: "",
    })
  }

  const updateMenuItem = async (index) => {
    const updatedItem = {
      ...dishes[index],
      // title_translations: dishes[index].title_translations,
      // description_translations: dishes[index].description_translations,
    }

    const updatedDish = {
      ...updatedItem,
      toppings: dishes[index].toppings, // Adding selected toppings to the newDish object
      extras: dishes[index].extras, // Adding selected Extras to the newDish object
      restaurant_id: restaurant_id,
    }

    if (!validateForm(updatedDish)) return // Check the form before submitting

    // console.log("updatedItem.id", updatedItem.id);
    // console.log("selectedToppings222", selectedToppings);
    // console.log("selectedExtras222", selectedExtras);
    console.log("updatedDish333", updatedDish)
    setLoading(true)

    try {
      const response = await axios.put(
        `${ baseURL }/dishes/${ updatedItem.id }`,
        updatedDish
      )

      console.log(`The ${ updatedItem.title } updated successful`)
      const updatedDishes = [...dishes]
      updatedDishes[index] = updatedDish

      setDishes(updatedDishes)
      setLoading(false)

      setSuccessAlert(`The ${ updatedItem.title } updated successful`)
      setTimeout(() => {
        setSuccessAlert(false)
      }, 5000)
    } catch (error) {
      setLoading(false)

      console.error('Error executing the "updateMenuItem" request:', error)
    }
  }

  const deleteMenuItem = async (index) => {
    setLoading(true)

    try {
      const dishIdToDelete = dishes[index].id // Get the ID of the dish to delete
      const response = await axios.delete(`${ baseURL }/dishes/${ dishIdToDelete }`)
      setLoading(false)

      console.log('The "deleteMenuItem" request was successful')

      const updatedDishes = [...dishes]
      updatedDishes.splice(index, 1) // Remove the element from the array
      setDishes(updatedDishes)

      setSuccessAlert(`The "deleteMenuItem" request was successful`)
      setTimeout(() => {
        setSuccessAlert(false)
      }, 5000)
    } catch (error) {
      setLoading(false)

      console.error('Error executing the "deleteMenuItem" request:', error)
    }
  }

  const updateAllDishes = async () => {
    console.log("dishes", dishes)
    console.log("originalDishes", originalDishes)

    // Function to compare objects
    const isEqual = (obj1, obj2) => {
      return JSON.stringify(obj1) === JSON.stringify(obj2)
    }

    setLoading(true)
    try {
      const updatedDishes = []
      for (const dish of dishes) {
        const originalDish = originalDishes.find((d) => d.id === dish.id)

        if (!isEqual(originalDish, dish)) {
          try {
            const response = await axios.put(
              `${ baseURL }/dishes/${ dish.id }`,
              dish
            )
            console.log(`Dish with ID ${ dish.id } successfully updated`)
            console.log(`Dish with title ${ dish.title } successfully updated`)
            setSuccessAlert(
              `Dish with title ${ dish.title } successfully updated`
            )

            // updatedDishes.push(response.data) // Assuming the server returns the updated dish
          } catch (error) {
            console.error(`Error updating dish with ID ${ dish.id }:`, error)
            updatedDishes.push(dish) // Save the original dish in case of an error
          }
        } else {
          updatedDishes.push(dish)
        }
      }

      // Update the state with new data
      // setDishes(updatedDishes)
      // setOriginalDishes(updatedDishes)

      getMenu()

      setLoading(false)
      setTimeout(() => {
        setSuccessAlert(false)
      }, 5000)
    } catch (error) {
      setLoading(false)
      console.error('Error executing the "updateAllDishes" request:', error)
    }
  }
  useEffect(() => {
    setErrorAlert(false) // Remove the change error message

    if (restaurant_id !== null) {
      getMenu()
      getToppings()
      getExtras()
      getGroups()
    }
  }, [])
  //   }, [user.nickname])

  const getTranslationsForText = async (text) => {
    const data = {
      text: text,
    }

    try {
      const result = await axios.post(`${ baseURL }/translations/`, data)
      console.log("result_getTranslationsForText :>> ", result)
      // setName("")
      // getGroups()
      setSuccessAlert(true)

      setTimeout(() => {
        setSuccessAlert(false)
      }, 3000)

      return result.data
    } catch (error) {
      console.error(
        'Ошибка при выполнении запроса "getTranslationsForText":',
        error
      )
    }
  }

  const translateToAll = async (e, currentTranslationField) => {
    // const { value } = e.target
    console.log("translations 1111:>> ", translations)

    console.log(" e.target :>> ", e.target)
    console.log("  currentTranslationField :>> ", currentTranslationField)
    console.log("  currentDishIndex :>> ", currentDishIndex)
    //  console.log('dishes[currentDishIndex] :>> ', dishes[currentDishIndex].description);
    console.log("dishes[currentDishIndex] :>> ", dishes[currentDishIndex].title)

    const request =
      currentTranslationField == "title"
        ? dishes[currentDishIndex].title
        : dishes[currentDishIndex].description

    const translationObj = await getTranslationsForText(request)

    console.log("translationObj :>> ", translationObj)

    if (currentTranslationField == "title") {
      setTranslations({
        title: translationObj,
      })
    } else {
      setTranslations({
        description: translationObj,
      })
    }

    console.log("translations 222:>> ", translations)

    // const updatedDishes = [...dishes]

    // if (currentTranslationField === "description") {
    //   updatedDishes[currentDishIndex].translations_descriptions =
    //     translations.description
    // } else {
    //   updatedDishes[currentDishIndex].translations = translations.title
    // }

    // setDishes(updatedDishes)
    // setOpenTranslationModal(false)
  }

  // ========================================================
  // ========================================================
  // ========================================================

  if (!loading && restaurant_id === null) {
    return (
      <Typography>
        You haven&apos;t created or chosen a restaurant yet
      </Typography>
    )
  }

  return (
    <>
      {loading && <LoadingOverlay />}

      {successAlert && (
        <Box className="notification">
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            <strong>
              {successAlert ? successAlert : "The operation was a success! "}
            </strong>
          </Alert>
        </Box>
      )}

      {errorAlert && (
        <Box className="notification">
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <strong>{errorAlert}</strong>
          </Alert>
        </Box>
      )}

      <Paper>
        <Box sx={{textAlign: "right"}}>
          <Button
            sx={{m: "5px "}}
            variant="contained"
            color="primary"
            onClick={() => updateAllDishes()}
          >
            Save All
          </Button>
        </Box>

        <Table>
          <TableHead>
            <TableRow sx={{backgroundColor: "lightBlue"}}>
              <StyledTableCell width="1%">ID</StyledTableCell>
              <StyledTableCell width="10%">Title</StyledTableCell>
              <StyledTableCell width="15%">Description</StyledTableCell>
              <StyledTableCell sx={{minWidth: 100}} width="10%">
                Price
              </StyledTableCell>

              <StyledTableCell width="10%">image</StyledTableCell>
              <StyledTableCell width="10%">Toppings</StyledTableCell>
              <StyledTableCell width="10%">Extras</StyledTableCell>
              <StyledTableCell width="10%">Group</StyledTableCell>

              <StyledTableCell width="5%">Actions</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {/* Creating a new dish ================================================ */}
            {/*   ================================================ */}
            {/*   ================================================ */}

            <TableRow sx={{backgroundColor: "lightBlue"}}>
              <StyledTableCell>
                {/* <TextField name='id'   variant='outlined' /> */}
              </StyledTableCell>

              <StyledTableCell>
                {/* <TextField
                  name="title"
                  value={newDish.title}
                  onChange={handleNewDishChange}
                  variant="outlined"
                /> */}

                <FullSizeTextarea
                  name="title"
                  value={newDish.title || ""}
                  onChange={handleNewDishChange}
                />
              </StyledTableCell>

              <StyledTableCell>
                <FullSizeTextarea
                  name="description"
                  value={newDish.description || ""}
                  onChange={handleNewDishChange}
                />
              </StyledTableCell>
              <StyledTableCell sx={{width: 100}}>
                {/* <FullSizeTextarea
                  name="price"
                  value={newDish.price}
                  onChange={handleNewDishChange}
                /> */}

                <TextField
                  name="price"
                  variant="outlined"
                  type="number"
                  InputProps={{
                    inputProps: {step: 0.1, min: 0}, // Установка атрибутов input
                  }}
                  value={newDish.price || ""}
                  onChange={handleNewDishChange}
                  placeholder="0.00"
                  fullWidth
                />
              </StyledTableCell>

              <StyledTableCell>
                <ImagePreview>
                  {productImg ? (
                    <>
                      <img src={productImg} alt="error!" />
                    </>
                  ) : (
                    <p> Image Preview </p>
                  )}
                </ImagePreview>
                <label htmlFor={`imgUpload`}>
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    sx={{
                      "borderRadius": 8,
                      "backgroundColor": "#2196f3",
                      "color": "#fff",
                      "&:hover": {backgroundColor: "#1976d2"},
                    }}
                  >
                    Upload
                  </Button>
                </label>
                <Input
                  id={`imgUpload`}
                  type="file"
                  inputProps={{
                    accept: "image/*",
                    style: {display: "none"},
                  }}
                  onChange={handleProductImageUpload}
                  required
                />
              </StyledTableCell>

              {/* choose toppings in new dish================ */}

              <StyledTableCell>
                {toppings && (
                  <Stack spacing={3}>
                    <Autocomplete
                      multiple
                      options={toppings}
                      getOptionLabel={(option) => option.title}
                      defaultValue={[...toppings]}
                      filterSelectedOptions
                      onChange={(event, newValue) => {
                        setNewSelectedToppings(newValue)
                      }}
                      value={newSelectedToppings}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="toppings for this dish"
                          placeholder="..."
                        />
                      )}
                    />
                  </Stack>
                )}
              </StyledTableCell>

              {/* choose extras in new dish================ */}

              <StyledTableCell>
                {extras && (
                  <Stack spacing={3}>
                    <Autocomplete
                      multiple
                      options={extras}
                      getOptionLabel={(option) => option.title}
                      defaultValue={[...extras]}
                      filterSelectedOptions
                      onChange={(event, newValue) => {
                        setNewSelectedExtras(newValue)
                      }}
                      value={newSelectedExtras}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="extras for this dish"
                          placeholder="..."
                        />
                      )}
                    />
                  </Stack>
                )}
              </StyledTableCell>

              {/* choose groups in new dish================ */}

              <StyledTableCell>
                {groups && (
                  <Stack spacing={3}>
                    <Autocomplete
                      options={groups}
                      getOptionLabel={(option) => option.name}
                      filterSelectedOptions
                      onChange={(event, newValue) => {
                        setNewSelectedGroup(newValue)
                      }}
                      value={newSelectedGroup}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Groups for this dish"
                          placeholder="..."
                        />
                      )}
                    />
                  </Stack>
                )}
              </StyledTableCell>

              <StyledTableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addNewDish}
                >
                  Add
                </Button>
              </StyledTableCell>
            </TableRow>

            {/* All dishes ================================================ */}
            {/*  ================================================ */}
            {/*   ================================================ */}

            {dishes.length > 0 &&
              dishes.map((item, index) => (
                <TableRow key={index}>
                  <StyledTableCell>{item.id}</StyledTableCell>

                  <StyledTableCell>
                    <FlexColumnContainer>
                      <FullSizeTextarea
                        value={item.title || ""}
                        onChange={(e) => handleEditChange(e, index, "title")}
                      />

                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          handleOpenTranslationModal(index, "title")
                        }
                      >
                        Translations
                      </Button>
                    </FlexColumnContainer>
                  </StyledTableCell>

                  <StyledTableCell>
                    <FlexColumnContainer>
                      <FullSizeTextarea
                        value={item.description || ""}
                        onChange={(e) =>
                          handleEditChange(e, index, "description")
                        }
                      />
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          handleOpenTranslationModal(index, "description")
                        }
                      >
                        Translations
                      </Button>
                    </FlexColumnContainer>
                  </StyledTableCell>

                  <StyledTableCell>
                    {/* <FullSizeTextarea
                      value={item.price}
                      onChange={(e) => handleEditChange(e, index, "price")}
                    /> */}

                    <TextField
                      // label="Введите число"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: {step: 0.1, min: 0}, // Установка атрибутов input
                      }}
                      value={item.price || ""}
                      onChange={(e) => handleEditChange(e, index, "price")}
                      placeholder="0.00"
                      fullWidth
                    />
                  </StyledTableCell>

                  <StyledTableCell>
                    <Box>
                      <ImagePreview>
                        {item.image ? (
                          <img src={item.image} alt="no_image!" />
                        ) : (
                          <Typography> Image Preview </Typography>
                        )}
                      </ImagePreview>

                      <label htmlFor={`imgUpload-${ index }`}>
                        <Button
                          variant="contained"
                          component="span"
                          startIcon={<CloudUploadIcon />}
                          sx={{
                            "borderRadius": 8,
                            "backgroundColor": "#2196f3",
                            "color": "#fff",
                            "&:hover": {backgroundColor: "#1976d2"},
                          }}
                        >
                          Upload
                        </Button>
                      </label>

                      <Input
                        id={`imgUpload-${ index }`}
                        type="file"
                        inputProps={{
                          accept: "image/*",
                          style: {display: "none"},
                        }}
                        onChange={(e) => {
                          const updatedList = [...dishes]
                          const file = e.target.files[0]
                          const reader = new FileReader()

                          if (file) {
                            reader.readAsDataURL(file)
                            reader.onloadend = () => {
                              updatedList[index].image = reader.result
                              setDishes(updatedList)
                            }
                          }
                        }}
                        required
                      />
                    </Box>
                  </StyledTableCell>

                  {/* list of toppings in dish ================================================ */}

                  <StyledTableCell>
                    {item.toppings && (
                      <Stack>
                        <Autocomplete
                          multiple
                          options={toppings}
                          getOptionLabel={(option) => option.title}
                          value={item.toppings}
                          defaultValue={item.toppings}
                          filterSelectedOptions
                          onChange={(event, newValue) => {
                            handleEditChange(
                              {target: {value: newValue}},
                              index,
                              "toppings"
                            )
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option?.id === value?.id
                          }
                          renderInput={(params) => (
                            <TextField
                              style={{width: "200px"}}
                              {...params}
                              label="toppings for this dish"
                              placeholder="..."
                            />
                          )}
                        />
                      </Stack>
                    )}
                  </StyledTableCell>

                  {/* list of extras in dish ================================================ */}

                  <StyledTableCell>
                    {item.extras && (
                      <Stack spacing={3}>
                        <Autocomplete
                          multiple
                          getOptionLabel={(option) => option.title}
                          defaultValue={item.extras}
                          options={extras}
                          value={item.extras}
                          onChange={(event, newValue) => {
                            handleEditChange(
                              {target: {value: newValue}},
                              index,
                              "extras"
                            )
                          }}
                          filterSelectedOptions
                          isOptionEqualToValue={(option, value) =>
                            option?.id === value?.id
                          }
                          renderInput={(params) => (
                            <TextField
                              style={{width: "200px"}}
                              {...params}
                              label="extras for this dish"
                              placeholder="..."
                            />
                          )}
                        />
                      </Stack>
                    )}
                  </StyledTableCell>

                  <StyledTableCell>
                    {groups && (
                      <Stack spacing={3}>
                        <Autocomplete
                          options={groups}
                          getOptionLabel={(option) => option.name}
                          value={
                            groups.find(
                              (group_obj) => group_obj.id === item.group_obj?.id
                            ) || null
                          }
                          filterSelectedOptions
                          onChange={(event, newValue) => {
                            handleEditChange(
                              {target: {value: newValue}},
                              index,
                              "group_obj"
                            )
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option?.id === value?.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select group"
                              placeholder="..."
                            />
                          )}
                        />
                      </Stack>
                    )}
                  </StyledTableCell>

                  <StyledTableCell>
                    <Button
                      sx={{m: "5px 0px"}}
                      variant="contained"
                      color="primary"
                      onClick={() => updateMenuItem(index)}
                    >
                      Update
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => deleteMenuItem(index)}
                    >
                      Delete
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>


      {console.log('translations >> ', translations)}
      <TranslationModal
        open={openTranslationModal}
        onClose={handleCloseTranslationModal}
        // translations={translations}
        translations={translations[currentTranslationField]}
        onTranslationChange={handleTranslationChange}
        onSave={handleSaveTranslations}
        onAutoTranslate={handleAutoTranslate}
        isLoading={isTranslating}
        currentTranslationField={currentTranslationField}
      />
    </>
  )
})
