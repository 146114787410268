import React from 'react';
import {Box, Typography, Button, Container, Paper, Grid} from '@mui/material';
import {FlexRowContainer, FlexColumnContainer} from "styles/styledComponents";

const HeroSection = () => {
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        pt: 8,
        pb: 6,
      }}
    >
      <Container maxWidth="md">

        {/* Добавленная строка с 4 элементами */}
        {/* TODO: доделать qr и маркетплейсы */}
        {/* <Grid container spacing={4} sx={{mb: 4}}>
          {[
            {name: "Restaurant", color: "red", url: "restaurant_url"},
            {name: "Burger Place", color: "orange", url: "burger_place_url"},
            {name: "Tea House", color: "brown", url: "tea_house_url"},
            {name: "Sex Shop", color: "pink", url: "sex_shop_url"},
          ].map((business, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Paper elevation={3} sx={{p: 2, textAlign: 'center'}}>
                <Typography variant="h6" gutterBottom>
                  {business.name}
                </Typography>
                <Box
                  sx={{
                    width: 100,
                    height: 100,
                    bgcolor: business.color,
                    mx: 'auto',
                    mb: 2,
                  }}
                />
                <Button variant="contained" color="primary" href={business.url} target="_blank">
                  Show
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid> */}


        {/* титл, дескрипшен и картинка */}
        <Box
          sx={{
            display: "flex",
            flexDirection: {xs: "column", md: "row"}, // "column" для малых экранов и "row" для больших
            justifyContent: "space-around",
            alignItems: "stretch", // Растягиваем элементы по высоте
            gap: 2,
          }}
        >
          <Box
            sx={{
              flex: 1,
              order: {xs: 1, md: 1}, // Текст будет первым и на малых, и на больших экранах
            }}
          >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Electronic menu by QR code for your business
            </Typography>

            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              Make an electronic QR menu and get a stylish app that works on guests' phones and tablets. Reduce staff and printing costs, collect guest base and customer feedback.
            </Typography>
          </Box>

          <Box
            sx={{
              flex: 1,
              order: {xs: 2, md: 2}, // Картинка будет второй на обоих типах экранов
              display: 'flex',
              justifyContent: {xs: 'center', md: 'flex-end'},
              alignItems: 'center', // Центрируем картинку по вертикали
            }}
          >
            <img
              src={`../images/startPage/demo.png`}
              alt="instruction"
              style={{
                // width: "100%", // Картинка будет занимать всю доступную ширину блока
                // height: "auto",
                maxHeight: "100%", // Ограничиваем высоту картинки высотой родительского блока
                objectFit: "contain", // Сохраняем пропорции изображения
              }}
            />
          </Box>
        </Box>



        {/* <Paper elevation={3} sx={{my: 4, p: 3, bgcolor: 'grey.100'}}>
          <Typography variant="body1" align="center" color="text.secondary" paragraph>
            Choose your business - choose your CodeShop          </Typography>
          <Typography variant="subtitle1" align="center" color="text.primary">
            - Yair Roshal, CEO
          </Typography>
        </Paper> */}

<Box sx={{py: 8}}>
  
          <Typography variant="h4" align="center" color="text.primary" gutterBottom p={2}>
            Key to Streamlined Communication and Targeted Success
          </Typography>
          <Typography variant="body1" align="center" color="text.secondary" paragraph>
            Unlock success with BurgerBot: Streamlined communication, laser-focused targeting, and business transformation at your fingertips.
          </Typography>
  
          <Box sx={{mt: 4, display: 'flex', justifyContent: 'center'}}>
            <a href="https://dev--burger-web-app.netlify.app/?restaurant_id=2" target="_blank" rel="noopener noreferrer">
              <Button variant="contained" color="primary" size="large">
                Menu example
              </Button>
            </a>
          </Box>
  
</Box>
        

      </Container>
    </Box>
  );
};

export default HeroSection;