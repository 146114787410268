import React, {useState} from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Box,
  useMediaQuery,
  useTheme,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageIcon from '@mui/icons-material/Language';
import FastfoodIcon from '@mui/icons-material/Fastfood';

import {useAuth0} from "@auth0/auth0-react"
import {Link} from "react-router-dom"

const Header = () => {
  const {loginWithRedirect, loginWithPopup} = useAuth0()

  const [anchorEl, setAnchorEl] = useState(null);
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageMenuOpen = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = () => {
    setLanguageAnchorEl(null);
  };

  const menuItems = [
    {label: 'About', href: '#about'},
    {label: 'Retails', href: '#retails'},
    {label: 'Business Card', href: '#business-card'},

  ];

  const languageOptions = [
    {label: 'ru', value: 'ru'},
    {label: 'he', value: 'he'},
    {label: 'en', value: 'en'},
  ];

  return (
    <AppBar position="static" color="default" elevation={1}>
      <Toolbar>
        <Box sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}>
          <FastfoodIcon sx={{mr: 1, color: theme.palette.primary.main}} />
          <Typography variant="h6" component="div" sx={{flexGrow: 1, fontWeight: 'bold', color: theme.palette.primary.main}}>
            BurgerBot
          </Typography>
        </Box>
        
        
        {/* Links==================== */}
        
        {/* {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {menuItems.map((item) => (
                <MenuItem key={item.label} onClick={handleMenuClose}>
                  <a href={item.href} style={{textDecoration: 'none', color: 'inherit'}}>
                    {item.label}
                  </a>
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          <Box sx={{display: 'flex'}}>
            {menuItems.map((item) => (
              <Button
                key={item.label}
                color="inherit"
                href={item.href}
                sx={{
                  textTransform: 'none',
                  mx: 1,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    transition: 'background-color 0.3s',
                  },
                }}
              >
                {item.label}
              </Button>
            ))}
          </Box>
        )} */}


{/* TODO: choose languages */}
        {/* <Link sx={{display: 'flex', alignItems: 'center'}}>
          <IconButton
            color="inherit"
            aria-label="language"
            onClick={handleLanguageMenuOpen}
          >
            <LanguageIcon />
          </IconButton>
          <Menu
            anchorEl={languageAnchorEl}
            open={Boolean(languageAnchorEl)}
            onClose={handleLanguageMenuClose}
          >
            {languageOptions.map((option) => (
              <MenuItem key={option.value} onClick={handleLanguageMenuClose}>
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </Link> */}



        <Link to="https://dev--burger-web-app.netlify.app/?restaurant_id=2" target="_blank">
          <Button
            variant="outlined"
            color="primary"
            sx={{
              textTransform: 'none',
              mx: 1,
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              },
            }}
          >
            Menu example
          </Button>
        </Link>




        <Link to="/" onClick={() => loginWithRedirect()} >
          <Button color="inherit" sx={{textTransform: 'none'}}>
            Login
          </Button>
        </Link>


        <Link to="/"
          onClick={() =>
            loginWithRedirect({
              screen_hint: 'signup',
            })
          }
        >
          <Button
            color="primary"
            variant="contained"
            sx={{textTransform: 'none'}}
          >
            Sign Up
          </Button>
        </Link>

        {/* 
          <Link to="/" onClick={() => loginWithPopup()} >
            <Button color="inherit" sx={{textTransform: 'none'}}>
            loginWithPopup
            </Button>
          </Link> */}

      </Toolbar>
    </AppBar>
  );
};

export default Header;