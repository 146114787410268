import React from 'react';
import { ThemeProvider, createTheme, CssBaseline, Box } from '@mui/material';
import Header from './Header';
import HeroSection from './HeroSection';
import ValuesSection from './ValuesSection';
import PlansSection from './PlansSection';
import Footer from './Footer';

const theme = createTheme({
  // You can customize the theme here
});

export function StartPage() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className="StartPage">
        <Header />
        <HeroSection />
        <ValuesSection />
        <PlansSection />
        {/* <Footer /> */}
      </Box>
    </ThemeProvider>
  );
}