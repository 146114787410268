import React, {useState, useEffect} from "react"
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  Box,
  Typography,
} from "@mui/material"
import axios from "axios"
import {TranslationModal} from "components/TranslationModal"
import {getTranslationsForText} from "utils/translationUtils"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import {baseURL} from "constants/api"
import {
  FlexRowContainer,
  FlexColumnContainer,
  StyledButton,
} from "styles/styledComponents"
import {useStateContext} from "contexts/userContext"
import {LoadingOverlay} from "components/LoadingOverlay"

export function Types() {
  const {restaurant_id} = useStateContext()
  const [typesList, setTypesList] = useState([])
  const [originalTypes, setOriginalTypes] = useState([])
  const [successAlert, setSuccessAlert] = useState(false)
  const [loading, setLoading] = useState(true)
  const [newType, setNewType] = useState("")
  const [openTranslationModal, setOpenTranslationModal] = useState(false)
  const [currentTranslationField, setCurrentTranslationField] = useState(null)
  const [currentTypeIndex, setCurrentTypeIndex] = useState(null)
  const [translations, setTranslations] = useState({
    title: {en: "", he: "", ru: "", fr: ""},
  })
  const [isTranslating, setIsTranslating] = useState(false)

  const getTypes = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${ baseURL }/types/${ restaurant_id }`)
      setTypesList(response.data)
      setOriginalTypes(JSON.parse(JSON.stringify(response.data)))
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error executing "getTypes" request:', error)
    }
  }

  const addType = async () => {
    const data = {
      type: newType,
      restaurant_id: restaurant_id,
      translations: {
        title: {en: "", he: "", ru: "", fr: ""},
      }
    }

    setLoading(true)
    try {
      await axios.post(`${ baseURL }/types/`, data)
      setNewType("")
      getTypes()
      setSuccessAlert("New type added successfully")
      setTimeout(() => {
        setSuccessAlert(false)
      }, 3000)
    } catch (error) {
      console.error('Error executing "addType" request:', error)
    } finally {
      setLoading(false)
    }
  }

  const updateType = async (id, updatedType) => {
    const data = {
      id: id,
      type: updatedType.type,
      restaurant_id: restaurant_id,
      translations: updatedType.translations,
    }

    console.log("updateType_data :>> ", data)
    setLoading(true)
    try {
      await axios.put(`${ baseURL }/types/`, data)
      setSuccessAlert(`Type "${ updatedType.type }" updated successfully`)
      setTimeout(() => {
        setSuccessAlert(false)
      }, 3000)
      getTypes()
    } catch (error) {
      console.error("Error updating type:", error)
    } finally {
      setLoading(false)
    }
  }

  const deleteType = async (id) => {
    setLoading(true)
    try {
      await axios.delete(`${ baseURL }/types/${ id }`)
      setSuccessAlert("Type deleted successfully")
      setTimeout(() => {
        setSuccessAlert(false)
      }, 3000)
      getTypes()
    } catch (error) {
      console.error("Error deleting type:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (restaurant_id !== null) {
      getTypes()
    }
  }, [restaurant_id])

  const handleInputChange = (event) => {
    setNewType(event.target.value)
  }

  const handleOpenTranslationModal = (index, field) => {
    setCurrentTypeIndex(index)

    console.log("field :>> ", field)
    setCurrentTranslationField(field)
    setTranslations({
      title: typesList[index].translations || {
        en: "",
        he: "",
        ru: "",
        fr: "",
      },
    })
    setOpenTranslationModal(true)
  }

  const handleCloseTranslationModal = () => {
    setOpenTranslationModal(false)
  }

  const handleTranslationChange = (e, language) => {
    const {value} = e.target
    setTranslations((prev) => ({
      ...prev,
      [currentTranslationField]: {
        ...prev[currentTranslationField],
        [language]: value,
      },
    }))
  }

  const handleSaveTranslations = () => {
    const updatedTypesList = [...typesList]
    updatedTypesList[currentTypeIndex].translations = translations.title
    setTypesList(updatedTypesList)
    setOpenTranslationModal(false)
  }

  const handleAutoTranslate = async () => {
    setIsTranslating(true)
    try {
      const request = typesList[currentTypeIndex].type

      const translationObj = await getTranslationsForText(request)
      setTranslations({
        title: translationObj,
      })
      setSuccessAlert("Auto-translation completed")
      setTimeout(() => {
        setSuccessAlert(false)
      }, 3000)
    } catch (error) {
      console.error("Error in auto-translation:", error)
    } finally {
      setIsTranslating(false)
    }
  }

  const updateItems = async () => {
    const isEqual = (obj1, obj2) => {
      return JSON.stringify(obj1) === JSON.stringify(obj2)
    }

    setLoading(true)
    try {
      for (const type of typesList) {
        const originalType = originalTypes.find((item) => item.id === type.id)
        if (!isEqual(originalType, type)) {
          const data = {
            ...type,
            restaurant_id: restaurant_id,
          }
          console.log("updateItems_data :>> ", data)

          try {
            await axios.put(`${ baseURL }/types`, data)
            setSuccessAlert(`Type "${ type.type }" updated successfully`)
          } catch (error) {
            console.error(`Error updating type with ID ${ type.id }:`, error)
          }
        }
      }
      getTypes()
      setTimeout(() => {
        setSuccessAlert(false)
      }, 5000)
    } catch (error) {
      console.error('Error executing the "updateItems" request:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {successAlert && (
        <Box className="notification">
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {successAlert}
          </Alert>
        </Box>
      )}

      {loading && <LoadingOverlay />}

      {!loading && typesList.length === 0 && (
        <Box sx={{width: "100%"}}>
          <Typography>You have not added types yet</Typography>
        </Box>
      )}

      <Paper>
        <Box sx={{textAlign: "right"}}>
          <Button
            sx={{m: "5px "}}
            variant="contained"
            color="primary"
            onClick={() => updateItems()}
          >
            Save All
          </Button>
        </Box>

        <Table>
          <TableHead>
            <TableRow sx={{backgroundColor: "lightBlue"}}>
              <TableCell>Type</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {typesList.map((type, index) => (
              <TableRow key={type.id}>
                <TableCell>
                  <FlexColumnContainer>
                    <TextField
                      value={type.type}
                      onChange={(e) => {
                        const updatedList = typesList.map((item) =>
                          item.id === type.id
                            ? {...item, type: e.target.value}
                            : item
                        )
                        setTypesList(updatedList)
                      }}
                    />
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleOpenTranslationModal(index, "title")}
                    >
                      Translations
                    </Button>
                  </FlexColumnContainer>
                </TableCell>

                <TableCell>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={() => updateType(type.id, type)}
                  >
                    Update
                  </StyledButton>
                  <StyledButton
                    variant="contained"
                    color="secondary"
                    onClick={() => deleteType(type.id)}
                  >
                    Delete
                  </StyledButton>
                </TableCell>
              </TableRow>
            ))}

            <TableRow sx={{backgroundColor: "lightBlue"}}>
              <TableCell>Type</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <TextField
                  label="Type"
                  name="type"
                  value={newType}
                  onChange={handleInputChange}
                  sx={{m: "10px"}}
                />
              </TableCell>

              <TableCell>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={addType}
                >
                  Add Type
                </StyledButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>

      <TranslationModal
        open={openTranslationModal}
        onClose={handleCloseTranslationModal}
        translations={translations.title}
        onTranslationChange={handleTranslationChange}
        onSave={handleSaveTranslations}
        onAutoTranslate={handleAutoTranslate}
        isLoading={isTranslating}
      />
    </>
  )
}
