import {useAuth0} from "@auth0/auth0-react"
import Avatar from "@mui/material/Avatar"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import {FlexColumnContainer} from "styles/styledComponents"
import {useStateContext} from "contexts/userContext"

export const Profile = () => {
  const {settings} = useStateContext()
  const {user, isAuthenticated} = useAuth0()
  {
    (settings?.logoImage || user?.picture) && (
      // {user?.picture && (
      <img
        className="avatar_img"
        style={{width: "50px", margin: "10px 10px 10px 40px"}}
        src={settings?.logoImage ? settings?.logoImage : user?.picture}
        alt={user?.nickname}
      />
    )
  }

  return (
    isAuthenticated && (
      <FlexColumnContainer sx={{m: 3}}>
        {(settings?.logoImage || user?.picture) && (
          <Avatar
            sx={{width: 100, height: 100, margin: "auto"}}
            src={settings?.logoImage ? settings?.logoImage : user?.picture}
            alt={user?.name}
          />
        )}
        {/* <Typography variant="h4">{user?.name}</Typography> */}

        {Object.keys(user).map((objKey, i) => (
          <Box key={i}>
            <Typography sx={{mt: 2}} variant="h5">
              {objKey}
            </Typography>
            <Typography noWrap>{user[objKey]}</Typography>
          </Box>
        ))}
      </FlexColumnContainer>
    )
  )
}
