import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Container } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SecurityIcon from '@mui/icons-material/Security';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';

const ValueCard = ({ title, description, icon: Icon }) => (
  <Grid item xs={12} sm={6} md={4}>
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <Icon fontSize="large" color="primary" />
        </Box>
        <Typography gutterBottom variant="h5" component="h3" align="center">
          {title}
        </Typography>
        <Typography variant="body2" align="center">
          {description}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

const ValuesSection = () => {
  const values = [
    {
      title: 'Human Service',
      description: 'We prioritize human connection, striving to respond promptly and provide the best solutions at any moment.',
      icon: PeopleIcon,
    },
    {
      title: 'Transparency',
      description: 'We believe in transparency, inviting you to ask anything and everything.',
      icon: VisibilityIcon,
    },
    {
      title: 'Timeliness',
      description: 'We are committed to delivering our products and services as quickly as possible, without delays.',
      icon: AccessTimeIcon,
    },
    {
      title: 'Trustworthiness',
      description: 'We protect you and your information. Our databases are registered with the Ministry of Justice, and we promise not to use your information without consent.',
      icon: SecurityIcon,
    },
    {
      title: 'Data-Driven',
      description: 'We capture every interaction, using data to refine our services and deliver personalized, effective solutions.',
      icon: DataUsageIcon,
    },
    {
      title: 'Understanding Needs',
      description: 'We understand you and prioritize tailoring our solutions to your needs, whether they are business or personal.',
      icon: EmojiObjectsIcon,
    },
  ];

  return (
    <Box sx={{ py: 8, bgcolor: 'background.default' }}>
      <Container maxWidth="lg">
        <Typography variant="h3" align="center" color="text.primary" gutterBottom>
          What Makes Us Different
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {values.map((value, index) => (
            <ValueCard key={index} {...value} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ValuesSection;