import React, {useState, useEffect} from "react"
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Box,
  Typography,
  Button,
} from "@mui/material"
import axios from "axios"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import {baseURL} from "constants/api"
import {StyledButton, FlexColumnContainer} from "styles/styledComponents"
import {useStateContext} from "contexts/userContext"
import {TranslationModal} from "components/TranslationModal"
import {getTranslationsForText} from "utils/translationUtils"
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

export function Groups() {
  const {restaurant_id} = useStateContext()
  const [groupsList, setGroupsList] = useState([])
  const [originalGroups, setOriginalGroups] = useState([])
  const [successAlert, setSuccessAlert] = useState(false)
  const [name, setName] = useState("")
  const [loading, setLoading] = useState(true)
  const [openTranslationModal, setOpenTranslationModal] = useState(false)
  const [currentGroupIndex, setCurrentGroupIndex] = useState(null)
  const [translations, setTranslations] = useState({
    title: {en: "", he: "", ru: "", fr: ""},
  })
  const [isTranslating, setIsTranslating] = useState(false)

  const handleDragEnd = (result) => {
    const {source, destination} = result;

    if (!destination) {
      return;
    }

    const reorderedGroups = Array.from(groupsList);
    const [removed] = reorderedGroups.splice(source.index, 1);
    reorderedGroups.splice(destination.index, 0, removed);

    setGroupsList(reorderedGroups);
  };

  const getGroups = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${ baseURL }/groups/${ restaurant_id }`);
      const sortedGroups = response.data.sort((a, b) => a.order - b.order);
      setGroupsList(sortedGroups);
      setOriginalGroups(JSON.parse(JSON.stringify(sortedGroups)));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error executing the "getGroups" request:', error);
    }
  };

  const addGroup = async () => {
    const data = {
      name: name,
      restaurant_id: restaurant_id,
      translations: {
        title: {en: "", he: "", ru: "", fr: ""},
      },
      order: null
    }

    setLoading(true)
    try {
      await axios.post(`${ baseURL }/groups/`, data)
      setName("")
      getGroups()
      setSuccessAlert(true)
      setTimeout(() => {
        setSuccessAlert(false)
      }, 3000)
    } catch (error) {
      console.error('Error executing the "addGroup" request:', error)
    } finally {
      setLoading(false)
    }
  }

  const updateGroup = async (index) => {
    // const updatedGroup = groupsList[index]

    const updatedGroup = {
      ...groupsList[index],
      restaurant_id: restaurant_id,
    }


    setLoading(true)
    try {
      await axios.put(`${ baseURL }/groups/`, updatedGroup)
      setSuccessAlert(true)
      setTimeout(() => {
        setSuccessAlert(false)
      }, 3000)
      console.log("Group successfully updated")
      getGroups()
    } catch (error) {
      console.error("Error updating group:", error)
    } finally {
      setLoading(false)
    }
  }

  const deleteGroup = async (index) => {
    setLoading(true)
    try {
      const groupIdToDelete = groupsList[index].id
      await axios.delete(`${ baseURL }/groups/${ groupIdToDelete }`)
      setSuccessAlert(true)
      setTimeout(() => {
        setSuccessAlert(false)
      }, 3000)
      console.log("Group successfully deleted")
      getGroups()
    } catch (error) {
      console.error("Error deleting group:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (restaurant_id !== null) {
      getGroups()
    }
  }, [restaurant_id])

  const handleInputChange = (event) => {
    setName(event.target.value)
  }

  const handleOpenTranslationModal = (index) => {
    setCurrentGroupIndex(index)
    setTranslations({
      title: groupsList[index].translations || {
        en: "",
        he: "",
        ru: "",
        fr: "",
      },
    })
    setOpenTranslationModal(true)
  }

  const handleCloseTranslationModal = () => {
    setOpenTranslationModal(false)
  }

  const handleTranslationChange = (e, language) => {
    const {value} = e.target

    console.log('language :>> ', language);
    console.log('e.target.value :>> ', e.target.value);

    setTranslations((prev) => ({
      ...prev,
      title: {
        ...prev.title,
        [language]: value,
      },
    }))
  }

  const handleSaveTranslations = () => {
    const updatedGroupsList = [...groupsList]
    updatedGroupsList[currentGroupIndex].translations = translations.title
    setGroupsList(updatedGroupsList)
    setOpenTranslationModal(false)
  }

  const handleAutoTranslate = async () => {
    setIsTranslating(true)
    try {
      const request = groupsList[currentGroupIndex].name

      console.log('groupsList[currentGroupIndex].name :>> ', groupsList[currentGroupIndex].name);
      console.log('groupsList :>> ', groupsList);
      console.log('currentGroupIndex :>> ', currentGroupIndex);


      const translationObj = await getTranslationsForText(request)
      setTranslations({
        title: translationObj,
      })
      setSuccessAlert(true)
      setTimeout(() => {
        setSuccessAlert(false)
      }, 3000)
    } catch (error) {
      console.error("Error in auto-translation:", error)
    } finally {
      setIsTranslating(false)
    }
  }

  const updateItems = async () => {
    setLoading(true);
    try {
      for (let i = 0; i < groupsList.length; i++) {
        const group = groupsList[i];
        const data = {
          ...group,
          restaurant_id: restaurant_id,
          order: i + 1, // Add this line to include the order
        };

        try {
          await axios.put(`${ baseURL }/groups`, data);
          console.log(`Group with ID ${ group.id } successfully updated`);
          setSuccessAlert(`Group "${ group.name }" successfully updated`);
        } catch (error) {
          console.error(`Error updating group with ID ${ group.id }:`, error);
        }
      }
      getGroups();
    } catch (error) {
      console.error('Error executing the "updateItems" request:', error);
    } finally {
      setLoading(false);
      setTimeout(() => {
        setSuccessAlert(false);
      }, 5000);
    }
  };


  const moveGroupUp = (index) => {
    if (index > 0) {
      const newList = [...groupsList];
      [newList[index - 1], newList[index]] = [newList[index], newList[index - 1]];
      setGroupsList(newList);
    }
  };

  const moveGroupDown = (index) => {
    if (index < groupsList.length - 1) {
      const newList = [...groupsList];
      [newList[index], newList[index + 1]] = [newList[index + 1], newList[index]];
      setGroupsList(newList);
    }
  };


  return (
    <>
      {successAlert && (
        <Box className="notification">
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            The operation was a — <strong>success!</strong>
          </Alert>
        </Box>
      )}

      {!loading && groupsList.length === 0 && (
        <Box sx={{width: "100%"}}>
          <Typography>You have not added groups yet</Typography>
        </Box>
      )}

      <Paper>
        <Box sx={{textAlign: "right"}}>
          <Button
            sx={{m: "5px "}}
            variant="contained"
            color="primary"
            onClick={() => updateItems()}
          >
            Save All
          </Button>
        </Box>

        <Table>
          <TableHead>
            <TableRow sx={{backgroundColor: "lightBlue"}}>
              <TableCell sx={{width: "10px"}}>ID</TableCell>
              <TableCell>Group</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupsList.map((group, index) => (
              <TableRow key={group.id}>
                <TableCell> {group.id} </TableCell>
                <TableCell>
                  <FlexColumnContainer>
                    <TextField
                      value={group.name}
                      onChange={(e) => {
                        const updatedList = [...groupsList]
                        updatedList[index].name = e.target.value
                        setGroupsList(updatedList)
                      }}
                    />
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleOpenTranslationModal(index)}
                    >
                      Translations
                    </Button>
                  </FlexColumnContainer>
                </TableCell>
                <TableCell>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={() => updateGroup(index)}
                  >
                    Update
                  </StyledButton>
                  <StyledButton
                    variant="contained"
                    color="secondary"
                    onClick={() => deleteGroup(index)}
                  >
                    Delete
                  </StyledButton>

                  <StyledButton
                    variant="outlined"
                    color="primary"
                    onClick={() => moveGroupUp(index)}
                    disabled={index === 0}
                  >
                    ▲
                  </StyledButton>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    onClick={() => moveGroupDown(index)}
                    disabled={index === groupsList.length - 1}
                  >
                    ▼
                  </StyledButton>

                </TableCell>
              </TableRow>
            ))}

            <TableRow sx={{backgroundColor: "lightBlue"}}>
              <TableCell>ID</TableCell>
              <TableCell>Group</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                {/* id empty */}
              </TableCell>
              <TableCell>
                <TextField
                  label="Group Name"
                  name="name"
                  value={name}
                  onChange={handleInputChange}
                  sx={{m: "10px"}}
                />
              </TableCell>
              <TableCell>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={addGroup}
                >
                  Add Group
                </StyledButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>

      <TranslationModal
        open={openTranslationModal}
        onClose={handleCloseTranslationModal}
        translations={translations.title}
        onTranslationChange={handleTranslationChange}
        onSave={handleSaveTranslations}
        onAutoTranslate={handleAutoTranslate}
        isLoading={isTranslating}
      />
    </>
  )
}