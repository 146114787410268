import React, {useState, useEffect} from "react"
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  TextField,
  Box,
  Input,
} from "@mui/material"
import {TranslationModal} from "components/TranslationModal"
import {getTranslationsForText} from "utils/translationUtils"
import axios from "axios"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"

import {
  FlexRowContainer,
  FlexColumnContainer,
  StyledButton,
  ImagePreview,
} from "styles/styledComponents"
import {baseURL} from "constants/api"
import {LoadingOverlay} from "components/LoadingOverlay"
import {useStateContext} from "contexts/userContext"

export function Extras() {
  const {restaurant_id} = useStateContext()
  const [extrasList, setExtrasList] = useState([])
  const [originalExtras, setOriginalExtras] = useState([])
  const [newExtra, setNewExtra] = useState({
    title: "",
    image: null,
    type: "",
  })
  const [logoImage, setLogoImage] = useState("")
  const [successAlert, setSuccessAlert] = useState(false)
  const [typesList, setTypesList] = useState([])
  const [loading, setLoading] = useState(true)
  const [openTranslationModal, setOpenTranslationModal] = useState(false)
  const [currentTranslationField, setCurrentTranslationField] = useState(null)
  const [currentExtraIndex, setCurrentExtraIndex] = useState(null)
  const [translations, setTranslations] = useState({
    title: {en: "", he: "", ru: "", fr: ""},
  })
  const [isTranslating, setIsTranslating] = useState(false)

  const getExtras = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${ baseURL }/extras/${ restaurant_id }`)
      setExtrasList(response.data)
      setOriginalExtras(JSON.parse(JSON.stringify(response.data)))
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error executing "getExtras" request:', error)
    }
  }

  const getTypes = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${ baseURL }/types/${ restaurant_id }`)
      setTypesList(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error executing "getTypes" request:', error)
    }
  }

  const addExtra = async () => {
    const data = {
      title: newExtra.title,
      image: logoImage,
      restaurant_id: restaurant_id,
      type_id: typesList.find((item) => item.type === newExtra.type).id,
    }

    setLoading(true)
    try {
      await axios.post(`${ baseURL }/extras/`, data)
      setNewExtra({
        title: "",
        image: null,
        type: "",
      })
      setLogoImage(null)
      getExtras()
      setSuccessAlert(true)
      setTimeout(() => {
        setSuccessAlert(false)
      }, 3000)
    } catch (error) {
      console.error('Error executing "addExtra" request:', error)
    } finally {
      setLoading(false)
    }
  }

  const updateExtra = async (index) => {
    const updatedExtra = extrasList[index]
    setLoading(true)
    try {
      await axios.put(`${ baseURL }/extras/`, updatedExtra)
      setSuccessAlert(true)
      setTimeout(() => {
        setSuccessAlert(false)
      }, 3000)
      getExtras()
    } catch (error) {
      console.error("Error updating extra:", error)
    } finally {
      setLoading(false)
    }
  }

  const deleteExtra = async (index) => {
    setLoading(true)
    try {
      const extraIdToDelete = extrasList[index].id
      await axios.delete(`${ baseURL }/extras/${ extraIdToDelete }`)
      setSuccessAlert(true)
      setTimeout(() => {
        setSuccessAlert(false)
      }, 3000)
      getExtras()
    } catch (error) {
      console.error("Error deleting extra:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (restaurant_id !== null) {
      getExtras()
      getTypes()
    }
  }, [restaurant_id])

  const handleProductImageUpload = (e) => {
    const file = e.currentTarget.files[0]
    TransformFileData(file)
  }

  const TransformFileData = (file) => {
    const reader = new FileReader()
    if (file) {
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        setLogoImage(reader.result)
      }
    } else {
      setLogoImage("")
    }
  }

  const handleInputChange = (event) => {
    const {name, value} = event.target
    setNewExtra({
      ...newExtra,
      [name]: value,
    })
  }

  const handleOpenTranslationModal = (index, field) => {
    setCurrentExtraIndex(index)
    setCurrentTranslationField(field)
    setTranslations({
      title: extrasList[index].translations || {
        en: "",
        he: "",
        ru: "",
        fr: "",
      },
    })
    setOpenTranslationModal(true)
  }

  const handleCloseTranslationModal = () => {
    setOpenTranslationModal(false)
  }

  const handleTranslationChange = (e, language) => {
    const {value} = e.target
    setTranslations((prev) => ({
      ...prev,
      [currentTranslationField]: {
        ...prev[currentTranslationField],
        [language]: value,
      },
    }))
  }

  const handleSaveTranslations = () => {
    const updatedExtrasList = [...extrasList]
    updatedExtrasList[currentExtraIndex].translations = translations.title
    setExtrasList(updatedExtrasList)
    setOpenTranslationModal(false)
  }

  const handleAutoTranslate = async () => {
    setIsTranslating(true)
    try {
      const request = extrasList[currentExtraIndex].title
      const translationObj = await getTranslationsForText(request)
      setTranslations({
        title: translationObj,
      })
      setSuccessAlert(true)
      setTimeout(() => {
        setSuccessAlert(false)
      }, 3000)
    } catch (error) {
      console.error("Error in auto-translation:", error)
    } finally {
      setIsTranslating(false)
    }
  }

  const updateItems = async () => {
    const isEqual = (obj1, obj2) => {
      return JSON.stringify(obj1) === JSON.stringify(obj2)
    }

    setLoading(true)
    try {
      for (const extra of extrasList) {
        const originalExtra = originalExtras.find((item) => item.id === extra.id)
        if (!isEqual(originalExtra, extra)) {
          try {
            await axios.put(`${ baseURL }/extras`, extra)
            setSuccessAlert(`Extra with title ${ extra.title } successfully updated`)
          } catch (error) {
            console.error(`Error updating extra with ID ${ extra.id }:`, error)
          }
        }
      }
      getExtras()
      setTimeout(() => {
        setSuccessAlert(false)
      }, 5000)
    } catch (error) {
      console.error('Error executing the "updateItems" request:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {successAlert && (
        <Box className="notification">
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {typeof successAlert === 'string' ? successAlert : 'The operation was successful!'}
          </Alert>
        </Box>
      )}

      {loading && <LoadingOverlay />}

      {!loading && extrasList.length === 0 && (
        <Box sx={{width: "100%"}}>
          <Typography>You have not added extras yet</Typography>
        </Box>
      )}

      <Paper>
        <Box sx={{textAlign: "right"}}>
          <Button
            sx={{m: "5px "}}
            variant="contained"
            color="primary"
            onClick={() => updateItems()}
          >
            Save All
          </Button>
        </Box>

        <Table>
          <TableHead>
            <TableRow sx={{backgroundColor: "lightBlue"}}>
              <TableCell>Title</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {extrasList?.length > 0 &&
              extrasList.map((extra, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <FlexColumnContainer>
                      <TextField
                        value={extra.title}
                        onChange={(e) => {
                          const updatedList = [...extrasList]
                          updatedList[index].title = e.target.value
                          setExtrasList(updatedList)
                        }}
                      />
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleOpenTranslationModal(index, "title")}
                      >
                        Translations
                      </Button>
                    </FlexColumnContainer>
                  </TableCell>

                  <TableCell>
                    <TextField
                      select
                      value={typesList.find((item) => item.id === extra.type_id)?.type}
                      onChange={(e) => {
                        const updatedList = [...extrasList]
                        updatedList[index].type_id = typesList.find(
                          (item) => item.type === e.target.value
                        )?.id
                        setExtrasList(updatedList)
                      }}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      {typesList.map((type) => (
                        <option key={type.id} value={type.type}>
                          {type.type}
                        </option>
                      ))}
                    </TextField>
                  </TableCell>

                  <TableCell>
                    <Box>
                      <ImagePreview>
                        {extra.image ? (
                          <img src={extra.image} alt="no_image!" />
                        ) : (
                          <Typography> Image Preview </Typography>
                        )}
                      </ImagePreview>

                      <label htmlFor={`imgUpload-${ index }`}>
                        <Button
                          variant="contained"
                          component="span"
                          startIcon={<CloudUploadIcon />}
                          sx={{
                            "borderRadius": 8,
                            "backgroundColor": "#2196f3",
                            "color": "#fff",
                            "&:hover": {backgroundColor: "#1976d2"},
                          }}
                        >
                          Upload
                        </Button>
                      </label>
                      <Input
                        id={`imgUpload-${ index }`}
                        type="file"
                        inputProps={{
                          accept: "image/*",
                          style: {display: "none"},
                        }}
                        onChange={(e) => {
                          const updatedList = [...extrasList]
                          const file = e.target.files[0]
                          const reader = new FileReader()

                          if (file) {
                            reader.readAsDataURL(file)
                            reader.onloadend = () => {
                              updatedList[index].image = reader.result
                              setExtrasList(updatedList)
                            }
                          } else {
                            setExtrasList([...extrasList])
                          }
                        }}
                        required
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={() => updateExtra(index)}
                    >
                      Update
                    </StyledButton>
                    <StyledButton
                      variant="contained"
                      color="secondary"
                      onClick={() => deleteExtra(index)}
                    >
                      Delete
                    </StyledButton>
                  </TableCell>
                </TableRow>
              ))}

            {/* Add new Extra */}
            <TableRow sx={{backgroundColor: "lightBlue"}}>
              <TableCell>Title</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <TextField
                  label="Title"
                  name="title"
                  value={newExtra.title}
                  onChange={handleInputChange}
                  sx={{m: "10px"}}
                />
              </TableCell>

              <TableCell>
                <TextField
                  select
                  name="type"
                  value={newExtra?.type}
                  onChange={handleInputChange}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="">None</option>
                  {typesList?.map((type) => (
                    <option key={type.id} value={type.type}>
                      {type.type}
                    </option>
                  ))}
                </TextField>
              </TableCell>

              <TableCell>
                <ImagePreview>
                  {logoImage ? (
                    <img src={logoImage} alt="error!" />
                  ) : (
                    <Typography> Image Preview </Typography>
                  )}
                </ImagePreview>

                <label htmlFor="imgUpload">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    sx={{
                      "borderRadius": 8,
                      "backgroundColor": "#2196f3",
                      "color": "#fff",
                      "&:hover": {backgroundColor: "#1976d2"},
                    }}
                  >
                    Upload
                  </Button>
                </label>
                <Input
                  id="imgUpload"
                  type="file"
                  inputProps={{
                    accept: "image/*",
                    style: {display: "none"},
                  }}
                  onChange={handleProductImageUpload}
                  required
                />
              </TableCell>

              <TableCell>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={addExtra}
                >
                  Add Extra
                </StyledButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>

      <TranslationModal
        open={openTranslationModal}
        onClose={handleCloseTranslationModal}
        translations={translations.title}
        onTranslationChange={handleTranslationChange}
        onSave={handleSaveTranslations}
        onAutoTranslate={handleAutoTranslate}
        isLoading={isTranslating}
      />
    </>
  )
}
